import React, { Component } from 'react';

import closeIcon from '../assets/img/icons-effacer.svg';

import Form from './Form';

/*********************/
/** Popup Component **/
/****** props ********/
/** props.day: Day selected **/
/** props.month:  selected **/
/** props.day: Day selected **/
/** props.popupClose: action close popup **/
/*************************/
class Popup extends Component {
  constructor(props) {
    super();
    this._handleKeyDown = this._handleKeyDown.bind(this);
  }

  _handleKeyDown = (event) => {
      switch( event.keyCode ) {
        case 27:
          this.props.popupClose();
          break;
        default: 
          break;
      }
  };

  componentWillMount(){
    document.addEventListener("keydown", this._handleKeyDown.bind(this));
  };

  componentWillUnmount() {
    document.removeEventListener("keydown", this._handleKeyDown.bind(this));
  };

  clickHandler = (e) => {
    e.preventDefault();
  }

  render() {
    return (
      <div className="popup_container">
        <div className="popup_background" onClick={this.props.popupClose}>
        </div>
        <div className="popup" onClick={this.clickHandler}>
          <img className="closeIcon" src={closeIcon} alt="close" onClick={this.props.popupClose} />

          <Form 
            day={this.props.day}
            month={this.props.month}
            year={this.props.year}
            popupClose={this.props.popupClose}
          />

        </div>
      </div>
    )
  }
}

export default Popup;