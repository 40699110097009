import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import logo from './assets/img/logo.jpg';
import { Header } from './components/Header';
import Calendar from './components/Calendar';
import CancelManagor from './components/CancelManagor';

import './assets/calendar.css';
import './assets/popup.css';
import './assets/form.css';
import './assets/alertBox.css';
import './assets/loader.css';
import './assets/cancelManagor.css';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Header logo={logo}/>
          <Route exact path="/" component={Calendar} />
          <Route exact path="/c/:uniqueId" component={CancelManagor} />
        </div>
      </Router>
    );
  }
}

export default App;
