// apiUrl: "http://127.0.0.1:5000",
// apiAdminUrl: "http://127.0.0.1:5000/admin",
// clientUrl: "http://127.0.0.1:3000",

// apiUrl: "http://my-booking-calendar.herokuapp.com",
// apiAdminUrl: "http://my-booking-calendar.herokuapp.com",
// clientUrl: "http://reservation.berryoptique.com",

export const config = {
    apiUrl: "http://my-booking-calendar.herokuapp.com",
    apiAdminUrl: "http://my-booking-calendar.herokuapp.com/admin",
    clientUrl: "http://reservation.berryoptique.com",
    // apiUrl: "http://127.0.0.1:5000",
    // apiAdminUrl: "http://127.0.0.1:5000/admin",
    // clientUrl: "http://127.0.0.1:3000",

    typeRdv: ['Examen de Vue', 'Adaptation des lentilles', 'Choix de lunette'],
    emailAdmin: 'admin@berryoptique.com',
};

export const urls = {
  add: config.apiUrl + '/add',
  month: config.apiUrl + '/month',
  day: config.apiUrl + '/day',
  cancel: config.apiUrl + '/cancel',
};

export const urls_admin = {
  hours: config.apiAdminUrl + '/hours',
  users: config.apiAdminUrl + '/users',
  getOne: config.apiAdminUrl + '/getOne',
  archives: config.apiAdminUrl + '/archives',
  manageHour: config.apiAdminUrl + '/manageHour',
  manageDay: config.apiAdminUrl + '/manageDay',
}

export const steps = {
  'users': 0,
  'hours': 1,
  'calendar': 2,
  'archives': 3,
  'userSelected': 4
}

export const month_name = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
export const day_name = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];

export const month_name_en = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const day_name_en = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];