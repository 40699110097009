import React, { Component } from 'react';
import Request from 'superagent';

import Popup from './Popup';
import { urls, month_name, day_name } from '../config';
import { Loader } from './Loader';



// const language = 0; // 0 EN : 1 FR TODO Implement

function ItemList(props) {
  const items = props.items;
  const listItems = items.map((item) =>
    <li className={props.className} key={item.toString()}>{item.substr(0,3)}</li>
  );
  return (
    <ul>{listItems}</ul>
  );
}

function isDayValid(day, month, year, monthDaysNotFree, isRoot) {
  const isYearOld = year <= new Date().getFullYear();
  const isMonthOld = month < new Date().getMonth();
  const isMonthSame = month === new Date().getMonth();
  const isDayOld = day < new Date().getDate();

  if (isRoot)
    return true;
  if (day in monthDaysNotFree)
    return false;
  if (isMonthOld)
    return isYearOld ? false : true;
  else if (isMonthSame)
    return isDayOld ? false : true;
  return true;
}

// Return month -12 to 12
function diffBetweenNowDateInMonth(month, year){
  if (year > new Date().getFullYear())
    return month + 1;
  else if (year < new Date().getFullYear())
    return -1 * ((11 - month) + 1);
  else {
    return (month > new Date().getMonth()) 
    ? (month - new Date().getMonth()) + 1
    : -1 * ((new Date().getMonth() - month) + 1);
  }
}

function DaysList(props) {
  const dayItems = props.dayItems.map((item) =>
    <li className={
      isDayValid(
        item,
        month_name.indexOf(props.monthActive),
        props.yearActive,
        props.monthDaysNotFree) ? "day_d" : "day_before"
    } key={item.toString()} onClick={ 
      isDayValid(
        item,
        month_name.indexOf(props.monthActive),
        props.yearActive,
        props.monthDaysNotFree,
        props.isRoot) ? () => props.onClick(item) : () => {}
    }><span>{item}</span></li>
  );
  const dayBeforeItems = props.dayBeforeItems.map((item) =>
    <li className="day_before" key={item.toString()}>{item}</li>
  );
  const dayAfterItems = props.dayAfterItems.map((item) =>
    <li className="day_after" key={item.toString()}>{item}</li>
  );

  return (
    <ul>
      {dayBeforeItems}
      {dayItems}
      {dayAfterItems}
    </ul>
  );
}

class Calendar extends Component {
  constructor(props) {
    super();

    this.state = {
      month : null,
      date: null,
      days_before: [],
      days: [],
      days_after: [],
      popup: false,
      day_selected: null,
      year: null,
      wait: true,
      monthDaysNotFree: {},
    }
    this.dayOnClick = this.dayOnClick.bind(this);
    this.popupClose = this.popupClose.bind(this);
  }

  componentDidMount() {
    this.calendarGenerator(new Date());
    this.monthManagor(new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate());
  }

  getMonth(){
    return month_name.indexOf(this.state.month);
  }

  /* API CALLS */
  monthManagor(date){
    Request
      .get(urls.month + '/' + date)
      .end((err, res) => {
        if (err){
          console.log('[ERROR] MONTH MANAGOR');
          return false;
        }

        const days = JSON.parse(JSON.parse(res.text).data).hours;
        if (days) {
          this.setState({
            monthDaysNotFree: days,
            wait: false,
          });
        }

      });
  }

  /* EVENTS */
  dayOnClick(day) {
    if (!this.state.popup){
      this.setState({popup: true});
      this.setState({day_selected: day});
    }
  }
  popupClose() {
    this.setState({popup: false});
    this.monthManagor(this.state.year + "-" + (this.getMonth() + 1) + "-1");
  }

  calendarGenerator(date) {
    var year = date.getFullYear();
    var month = date.getMonth();
    var monthName = month_name[month];

    var max_day = parseInt((new Date(year,month+1,0) + "").substr(8, 2), 10);
    var first_day = new Date(year,month,1).getDay();

    var max_day_month_before = parseInt((new Date(year,month,0) + "").substr(8, 2), 10);
    var annexeTab_before = [];
    // If it's sunday, add one week
    if (first_day === 0) first_day = 7;
    for (var i = first_day; i > 1; i--)
      annexeTab_before.push(max_day_month_before--);
    annexeTab_before.reverse();

    var max_day_month_after = 7 - ((annexeTab_before.length + max_day) % 7);
    if (max_day_month_after === 7) {max_day_month_after = 0}
    var annexeTab_after = [];
    for (var j = 0; j < max_day_month_after; j++)
      annexeTab_after.push(j + 1);

    var daysTab = Array(max_day).fill(null);
    daysTab.forEach((value, j) => {
      daysTab[j] = j + 1;
    });

    this.setState({month: monthName});
    this.setState({date: date});
    this.setState({days_before: annexeTab_before});
    this.setState({days: daysTab});
    this.setState({days_after: annexeTab_after});
    this.setState({year: year});
  }

  async changeMonth(isAdd){    
    const md = diffBetweenNowDateInMonth(this.getMonth(), this.state.year);
    
    if ((isAdd && md < 2) || (!isAdd && md > -1)){
      this.setState({wait: true});
      await this.calendarGenerator(new Date(this.state.date.getFullYear(),
        (isAdd ? this.state.date.getMonth() + 1 : this.state.date.getMonth() - 1)
      ));
      this.monthManagor(this.state.year + "-" + (this.getMonth() + 1) + "-1");

    }
  }

  render() {
    return (
      <div className="calendarContain">
        {this.state.wait && <Loader />}
        { this.state.popup && <Popup 
          day={this.state.day_selected}
          month={this.state.month}
          year={this.state.year}
          popupClose={this.popupClose}
          isRoot={this.props.admin}
        /> }
         <div className="App-title">
            <div className="pagination"></div>
            <span className="arrow left" onClick={this.changeMonth.bind(this, false)}></span>
            <h3 className="calendarMonth">{this.state.month} {this.state.year}</h3>
            <span className="arrow right" onClick={this.changeMonth.bind(this, true)}></span>
          </div>
        <div>
          <div className="calendar-title">
            <ItemList items={day_name}/>
          </div>
          <div className="calendar">
            <DaysList 
              dayItems={this.state.days} 
              dayBeforeItems={this.state.days_before} 
              dayAfterItems={this.state.days_after}
              monthActive= {this.state.month}
              yearActive= {this.state.year}
              monthDaysNotFree={this.state.monthDaysNotFree}
              isRoot={this.props.admin}
              onClick={this.dayOnClick}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Calendar;