import React, { Component } from 'react';
import Request from 'superagent';
import { Link } from 'react-router-dom';
import { urls } from '../config';

class CancelManagor extends Component {
  constructor(props) {
    super();

    this.state = {
      showError: false,
      showErrorPopup: {
        isError: false,
        msg: false,
        display: false,
      },
      everythingSubmited: false,
    }

    this.cancelCall(props.match.params.uniqueId);
  }

  /* API CALLS */
  cancelCall(uniqueId){
    Request
      .get(urls.cancel + '/' + uniqueId)
      .end((err, res) => {
        if (err){
          console.log('[ERROR] HOURS MANAGOR'); // TODO ERROR MESSAGE POPUP
          return false;
        }
      });
  }

  render() {
    return (
      <div className="cancelManagor">
        <h3>Merci, votre demande d'annulation a bien été prise en compte.</h3>
        <Link to='/'><button>Retour</button></Link>
      </div>
    )
  }
}

export default CancelManagor;