import React from 'react';

export const Header = (props) => 
        <div className="App-header">
          <header>
            <img src={props.logo} className="App-logo" alt="logo" />
            <div>
              <p><strong>Berry Optique</strong> vous accueille dans son magasin indépendant proche de chez vous <br /><br /><strong>4 rue André Lescaroux, Châteauroux</strong>.</p>
              <p>N'hésitez pas à prendre rendez-vous</p>
            </div>
          </header>
        </div>