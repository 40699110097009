import React, { Component } from 'react';
import Request from 'superagent';

import calendarIcon from '../assets/img/calendar.png';
import successIcon from '../assets/img/success.png';
import { config, urls, month_name } from '../config';
import { AlertBox } from './AlertBox';

function HorairesList(props) {
  const horaires = props.horaires;
  const listItems = horaires.map((entry) => 
    <div
      key={entry.start}
      className={ 
        entry.valid ? 
          entry.start === props.selected ? 
          'creneau selected'
          : 'creneau'
        : 'creneau unable' }
      onClick={entry.valid ? () => props.clickOnHoraire(entry.start): () => {}}
    >
      {entry.start.split(':')[0] + "h" + entry.start.split(':')[1]}
    </div>
  );
  return (
    <div className="horaires">
      {props.horaires.length > 0 && listItems}
      {props.horaires.length === 0 && <div className="loader">Loading...</div>}
    </div>
  )
}

function ValidBooking(props) {
  return (!props.date) ? '' : (
    <div className="validBooking">
        <img src={successIcon} alt="validBooking" />
        <p>Confirmation de votre prise de rendez-vous</p>
        <p>pour le <strong>
          {new Date(props.date.split(' ')[0]).toLocaleDateString('fr-FR') + " à " + props.date.split(' ')[1]}
        </strong>.</p>
        <p>Un email de confirmation vous a été envoyé.</p>
        <button onClick={props.onClickClose}>Fermer</button>
    </div>
  )
}


class Form extends Component {
  constructor(props) {
    super();

    this.nomInput = React.createRef();
    this.prenomInput = React.createRef();
    this.emailInput = React.createRef();
    this.telInput = React.createRef();
    this.typeSelect = React.createRef();

    this.state = {
      form : {
        nom : null,
        prenom : null,
        email : null,
        tel : null,
        horaire: null,
        date_rdv: '2019-01-01 15:15',
        type_rdv: 0,
      },
      formAction: {
        nom : false,
        prenom : false,
        email : false,
        tel : false,
        horaire: false,
      },
      formValid: false,
      showError: false,
      showErrorPopup: {
        isError: false,
        msg: false,
        display: false,
      },
      everythingSubmited: false,
      horaires: [],
    }
    /* Horaires : { horaire, duree, validite } */

    this.hoursManagor(props.year + "-" + (month_name.indexOf(props.month) + 1) + "-" + props.day);

    this.focus = this.focus.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.clickOnSubmit = this.clickOnSubmit.bind(this);
    this.clickOnHoraire = this.clickOnHoraire.bind(this);
    this.verifFormConstant = this.verifFormConstant.bind(this);
    this.verifFormBeforeSubmit = this.verifFormBeforeSubmit.bind(this);
    this.onBlurInput = this.onBlurInput.bind(this);
    this.callbackSubmit = this.callbackSubmit.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.state.pid);
  }

  alertBoxManagor(isError, msg, delay) {
    let values = this.state.showErrorPopup;
    values['display'] = true;
    values['msg'] = msg;
    values['isError'] = isError;
    this.setState({showErrorPopup: values});

    const pid = setTimeout(
      function() {
        let values = this.state.showErrorPopup;
        values['display'] = false;
        this.setState({
          showErrorPopup: values
        });
      }.bind(this),
      delay);
    if (this.state.pid) { clearTimeout(this.state.pid); }
    this.setState({pid: pid});

    return true;
  }

  onChangeInput(typeInput, value){
    let values = this.state.form;
    values[typeInput] = value;
    this.setState({
      form: values
    });
    this.verifFormConstant();
  }

  onBlurInput() {
    //console.log('onBlur');
  }

  focus(typeInput) {
    this[typeInput].current.focus();
  }

  verifFormConstant() {
    let mbool = false;

    for (let property in this.state.form) {
      if ((this.state.form[property] === null || this.state.form[property] === '') && property !== 'tel'){
        mbool = true;
        if (this.state.formValid){
          this.setState({
            formValid: false,
            showError: true,
          });
        }
      }
    }

    if (!mbool && !this.state.formValid) {
      this.setState({
        formValid: true,
        showError: false
      })
    }
  }

  async verifFormBeforeSubmit() {
    let mbool = false;
    const emailCheck = /^(([^<>()[]\.,;:s@]+(.[^<>()[]\.,;:s@]+)*)|(.+))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/;
    const nameCheck = /^[a-zA-Z]*$/;
    const telCheck = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;

    for (let property in this.state.form) {
      if (this.state.form[property] !== null && this.state.form[property] !== ''){
        if (property === 'email' && !emailCheck.test(this.state.form[property]))
          mbool = this.alertBoxManagor(true, 'Email incorrect', 5000);
        else if (property === 'nom' && !nameCheck.test(this.state.form[property]))
          mbool = this.alertBoxManagor(true, 'Nom incorrect', 5000);
        else if (property === 'prenom' && !nameCheck.test(this.state.form[property]))
          mbool = this.alertBoxManagor(true, 'Prenom incorrect', 5000);
        else if (property === 'tel' && !telCheck.test(this.state.form[property]))
          mbool = this.alertBoxManagor(true, 'Numéro de téléphone mal rempli', 5000);
      }
    }

    if (mbool) {
      this.setState({
        formValid: false,
        showError: true
      });
    }
  }

  /* API CALLS */
  hoursManagor(date){
    Request
      .get(urls.day + '/' + date)
      .end((err, res) => {
        if (err){
          console.log('[ERROR] HOURS MANAGOR'); // TODO ERROR MESSAGE POPUP
          return false;
        }

        const booked = JSON.parse(JSON.parse(res.text).data).hours;
        const available = JSON.parse(JSON.parse(res.text).data).available;

        if (available[0] !== '') {
          this.setState({
            horaires: available.map((elt) => (booked.indexOf(elt) !== -1) ? { start: elt, duree: 1, valid: false }
              : { start: elt, duree: 1, valid: true } )
          });
        }

      });
  }

  clickOnHoraire(horaire) {
    const month = month_name.indexOf(this.props.month) + 1;
    const MyDate = new Date(
      Date.parse(
        month + " " + this.props.day + "," + this.props.year
      ));
    this.onChangeInput('date_rdv', this.props.year + '-'
      + (MyDate.getMonth() + 1) + '-'
      + (MyDate.getDate()) + ' '
      + horaire);
    this.onChangeInput('horaire', horaire);
    this.verifFormConstant();
  }

  callbackSubmit(err, res) {
    if (err)
      this.alertBoxManagor(true, 'La requête a échoué', 5000);
    else {
      this.setState({
        'everythingSubmited': true,
      });
    }
  }

  async clickOnSubmit() {
    await this.verifFormBeforeSubmit();
    if (!this.state.formValid){
      this.setState({
        showError: true
      });
    }
    else {
      Request
      .post(urls.add)
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .send({ 
        firstName: this.state.form.prenom,
        lastName: this.state.form.nom,
        email: this.state.form.email,
        telephone: this.state.form.tel,
        date_rdv: this.state.form.date_rdv,
        type_rdv: this.state.form.type_rdv
      })
      .end(this.callbackSubmit);
    }
  }

  render() {
    return (
      <div className="form">
        
        {this.state.showErrorPopup.display && this.state.showErrorPopup.isError &&
          <AlertBox 
            isError={this.state.showErrorPopup.isError}
            msg={this.state.showErrorPopup.msg}
            display={this.state.showErrorPopup.display}
          />}

        {this.state.everythingSubmited &&
          <ValidBooking 
            date={this.state.form.date_rdv} 
            onClickClose={this.props.popupClose}
          />
        }

        {!this.state.everythingSubmited && 
          <div> 
            <h4><span>R</span>eserver un <span>c</span>reneau</h4>
            <div className="memoDay">
              <img src={calendarIcon} alt="calendar" />
              Le {this.props.day} {this.props.month} {this.props.year}
            </div>
            <form>
              <div className="fullName">
                <div>
                  <input
                    onChange={(e) => this.onChangeInput('nom', e.target.value)}
                    ref={this.nomInput}
                    type="text"
                    name="nom"
                    onBlur={(e) => this.onBlurInput('nom', e.target.value)}
                    required />
                  <label className={this.state.form.nom ? 'none' : ''} onClick={() => this.focus("nomInput")}>Nom</label>
                </div>
                <div>
                  <input
                    onChange={(e) => this.onChangeInput('prenom', e.target.value)}
                    ref={this.prenomInput}
                    type="text"
                    name="prenom"
                    required />
                  <label className={this.state.form.prenom ? 'none' : ''} onClick={() => this.focus("prenomInput")}>Prenom</label>
                </div>
              </div>
              <div>
                <input
                  onChange={(e) => this.onChangeInput('email', e.target.value)}
                  ref={this.emailInput}
                  type="email"
                  name="email"
                  required />
                <label className={this.state.form.email ? 'none' : ''} onClick={() => this.focus("emailInput")}>Email</label>
              </div>
              <div>
                <input
                  onChange={(e) => this.onChangeInput('tel', e.target.value)}
                  ref={this.telInput}
                  type="num"
                  name="tel"
                  required />
                <label className={this.state.form.tel ? 'none' : ''} onClick={() => this.focus("telInput")}>Telephone</label>
              </div>
              <div>
                <select
                  onChange={(e) => this.onChangeInput('type_rdv', e.target.value)}
                  ref={this.typeSelect}
                  value={this.state.form.type_rdv}>
                  {config.typeRdv.map((type) => 
                    <option value={config.typeRdv.indexOf(type)} key={config.typeRdv.indexOf(type)}>{type}</option>
                  )}
                </select>
              </div>
              <h3 className="title_heures">Horaires disponibles</h3>
              <HorairesList
                horaires={this.state.horaires}
                selected={this.state.form.horaire}
                clickOnHoraire={this.clickOnHoraire}
              />
              <div>
                <input 
                  className={ this.state.formValid ? 'valid': 'unvalid' }
                  type="submit"
                  onClick={this.clickOnSubmit}
                  value="réserver" />
                {this.state.showError && <h3 className="validMessageInfo">Certains champs sont invalides</h3>}
              </div>
            </form>
          </div>
        }

      </div>
    )
  }
}

export default Form;